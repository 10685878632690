import React from 'react';


// const api_endpoint = "https://eth-rinkeby.alchemyapi.io/v2/" + alchemyKey;
// const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
// const web3A = createAlchemyWeb3(api_endpoint);
const {network, api_endpoint, contractAddress} = getenvVars();

// console.log(network + ' ALCHEMY API ENDPOINT -> ' + api_endpoint);
// console.log(network + ' ALCHEMY CONTRACT -> ' + contractAddress);

const contractABI = require('../contract-abi.json')
const Web3  = require('web3');
var web3 = new Web3(Web3.givenProvider || api_endpoint);
//var web3 = new Web3(Web3.givenProvider);





function getenvVars() {
    let key = "";;
    let contract = "";
    let networkName = "";
    let endpoint = ""

    //Remove await keyword not sure if matters?
    // const contractObj = new web3.eth.Contract(contractABI, contractAddress);

    //Add with env vars for (tot free mints, total per tx, total per wallet, etc)


    if (process.env.REACT_APP_CHAIN === "4") {
        key = process.env.REACT_APP_ALCHEMY_KEY_RINK;
        endpoint = "https://eth-rinkeby.alchemyapi.io/v2/" + key;
        contract = process.env.REACT_APP_CONTRACT_RINK; 
        networkName = "Rinkeby";
    }
    else if (process.env.REACT_APP_CHAIN === "1") {
        key = process.env.REACT_APP_ALCHEMY_KEY_MAIN;
        endpoint = "https://eth-mainnet.alchemyapi.io/v2/" + key;
        contract = process.env.REACT_APP_CONTRACT_MAIN;
        networkName = "MAINNET";
    }
    else {
        key = process.env.REACT_APP_ALCHEMY_KEY_MAIN;
        endpoint = "https://eth-mainnet.alchemyapi.io/v2/" + key;
        contract = process.env.REACT_APP_CONTRACT_MAIN; 
        networkName = "MAINNET";
    }

    return {
        api_endpoint: endpoint,
        contractAddress: contract,
        network: networkName,
    };
}


export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });

            let networkName = "";
            let chainID = "";
            if (process.env.REACT_APP_CHAIN === "4") {
                //console.log('RINKEBY');
                //console.log(process.env.REACT_APP_CHAIN);
                networkName = "Rinkeby";
                chainID = "0x4";
            }
            else if (process.env.REACT_APP_CHAIN === "1") {
                //console.log('MAINNET');
                //console.log(process.env.REACT_APP_CHAIN);
                networkName = "Mainnet";
                chainID = "0x1";
            }

            let network = window.ethereum.networkVersion;
            // console.log("Network Pre-Switch " + network);

            if (network === process.env.REACT_APP_CHAIN) {
                const obj = {
                    status: "Wallet Connected!",
                    address: addressArray[0],
                    network: network,
                    connected: true,
                };
                return obj;
            }   
            else {
                //console.log(networkName);
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chainID }], // chainId must be in hexadecimal numbers
                });  

                const obj = {
                    status: "Wallet Connected!",
                    address: addressArray[0],
                    network: network,
                    connected: true,
                };
                return obj;                
            }
        } catch (error) {
            return {
                address: "",
                status: "ERROR: " + error.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                        {" "}
                        <span role="img" aria-label="metamask fox">🦊</span>{" "}
                        <a target="_blank" href={`https://metamask.io/download.html`}>
                            Metamask not found dummy.
                        </a>
                </span>
            )
        };
    }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        let network = window.ethereum.networkVersion
        // console.log({network})
        
        if (addressArray.length > 0) {
            let networkName = "";
            let chainID = "";
            if (process.env.REACT_APP_CHAIN === "4") {
                //console.log('RINKEBY');
                //console.log(process.env.REACT_APP_CHAIN);
                networkName = "Rinkeby";
                chainID = "0x4";
            }
            else if (process.env.CHAIN === "1") {
                //console.log('MAINNET');
                //console.log(process.env.REACT_APP_CHAIN);
                networkName = "Mainnet";
                chainID = "0x1";
            }

            if (network === process.env.REACT_APP_CHAIN) {
                const obj = {
                    status: "Wallet Connected!",
                    address: addressArray[0],
                    network: network,
                    connected: true,
                };
                return obj;
            }   
            else {
                if (network != process.env.REACT_APP_CHAIN) web3 = new Web3(api_endpoint);
                //console.log(networkName);
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chainID }], // chainId must be in hexadecimal numbers
                });  

                network = window.ethereum.networkVersion;

                const obj = {
                    status: "Wallet Connected!",
                    address: addressArray[0],
                    network: network,
                    connected: true,
                };
                return obj;                
            }
        } else {
          if (network != process.env.REACT_APP_CHAIN) web3 = new Web3(api_endpoint);
          return {
            address: "",
            status: "🦊 Connect to Metamask if u wanna dig Gremlinz",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                Metamask not found dummy
              </a>
          </span>
        ),
      };
    }
};


export const getWalletMintAmount = async (walletAddy) => {
    const contract = await new web3.eth.Contract(contractABI, contractAddress);
    
    const walletHasMinted = await contract.methods.balanceOf(walletAddy).call();
    //console.log("TOTAL MINTED BY WALLET -> " + walletHasMinted);
    return walletHasMinted;
}


export const getSupply = async () => {
    const contract = await new web3.eth.Contract(contractABI, contractAddress);

    //Change to total supply function
    const maxSupply = await contract.methods.gremlinz().call();
    
    // console.log("MAX SUPPLY -> " + maxSupply);

    const mintedAmt = await contract.methods.totalSupply().call();
    // console.log("SUPPLY -> " + mintedAmt);

    return {
        mintedAmt: mintedAmt,
        totalSupply: maxSupply,
    };
};

export const getMints = async (maxSupply) => {
    const contract = await new web3.eth.Contract(contractABI, contractAddress);
    /*
    const mintedAmt = await contract.methods.totalSupply().call((err, result) => {
        if(err){
          console.error('Error: ', err);
          // handle the error here
        }
      
        let amount = result;
        // console.log("Supply: " + result);

        return amount;  
      });
    */
    // console.log("MAX SUPPLY -> " + maxSupply);
    const mintedAmt = await contract.methods.totalSupply().call();
    
    if (mintedAmt == maxSupply) {
        return  {
            mintedUpdate: mintedAmt,
            saleEnd: true,
        }
    }
    else {
        return  {
            mintedUpdate: mintedAmt,
            saleEnd: false,
        }
    };

};



  export const mintNFT = async (amt, price) => {

    let txURL = "";
    if (process.env.REACT_APP_CHAIN === "4") {
        //console.log('RINKEBY');
        //console.log(process.env.REACT_APP_CHAIN);
        txURL = "https://rinkeby.etherscan.io/tx/";
    }
    else if (process.env.CHAIN === "1") {
        //console.log('MAINNET');
        //console.log(process.env.REACT_APP_CHAIN);
        txURL = "https://etherscan.io/tx/";
    }

    

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    //set up your Ethereum transaction
    
    // Price / weiValue is only needed for Whale Watcher contract
    let totalPrice = amt * price;
    totalPrice = totalPrice.toString();
    let weiValue = web3.utils.toWei(totalPrice, 'ether'); 

    console.log("PRICE: " + totalPrice + " + gasss");

    //console.log("Price " + weiValue);
    weiValue = web3.utils.numberToHex(weiValue);
    //console.log("Price " + weiValue);
    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: weiValue, // PRICE TO PAY FOR MINT.  CAN BE ZERO!
        count: amt, // Number of tokens to mint
        'data': window.contract.methods.digDigGremlin(amt).encodeABI()//make call to NFT smart contract 
    };

    //sign the transaction via Metamask
    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        }); 
    return {
        success: true,
        status: (<span style={{color:'green'}}>✅ &nbsp; Success! <a target='_blank' href={txURL + txHash}>Click here to view transaction on Etherscan</a></span> )
    }
    } catch (error) {
    return {
        success: false,
        status: "😥  Something went wrong: " + error.message
    }

    }    
};

