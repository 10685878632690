import React from 'react';
import './App.css';
// import HeaderIcons from './Header';
// import Minter from './Minter-Orig'
import MinterGremlinz from './MinterGremlinz'





function App() {
  // console.log('App render');
  return (
    <div className="App">
      <MinterGremlinz></MinterGremlinz>
    </div>
  );
}

export default App;
