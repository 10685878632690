import React from 'react';
import { useEffect, useState } from "react";
import { 
  connectWallet,
  getCurrentWalletConnected,
  getSupply,
  getMints,
  getWalletMintAmount,
  mintNFT 
} from './utils/interact';

import { useRef } from 'react'

const MinterGremlinz = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [walletConnected, setConnected] = useState(false);
  const [status, setStatus] = useState("");
  const [network, setNetwork] = useState("");
  const [mintedAmt, setMinted] = useState("0");
  const [totalSupply, setTotal] = useState("0");
  const [soldOut, setSoldOut] = useState(false);
  const [curPrice, setPrice] = useState("DUNNO?!");
  const [curPriceNum, setPriceNum] = useState("");
  const [counter, setCounter] = useState(1);
  const [shortContract, setShortContract] = useState("");
  const [qtyButtonDisabled, setqtyButtonDisabled] = useState(true);

  const counterRef = useRef(counter);
  const curPriceNumRef = useRef(curPriceNum);
  const walletAddressRef = useRef(walletAddress);

  counterRef.current = counter;
  curPriceNumRef.current = curPriceNum;
  // walletAddressRef.current = walletAddress;

  //20 Seconds
  const INTEVERAL_MS = 20000;
  //const INTEVERAL_MS = 3000;
  var MAX_SUPPLY = 0;

  const [enterButtonDisabled, setEnterButtonDisabled] = useState(true);
  const [isBonerActive, setBonerActive] = useState("false");
  const [goUnderShow, setGoUnderShow] = useState("true");
  const [enterButtonClass, setEnterButtonClass] = useState("disabled");

  let fukinGremliz = useRef();
  let fuckinGremlinFlex = useRef();
 
  useEffect(() => {
    //Changed useEffect from aysnc to containing function with async
      async function fetchData() {
      await delay(3)
      boner()
      // await delay(3)
      // allowEntry()      

      const {address, status, network, connected} = await getCurrentWalletConnected();

      walletAddressRef.current = address;

      const {mintedAmt, totalSupply} = await getSupply();
      // var mintedAmt = await getMints();
      // console.log("useEffect Mints: " + mintedAmt);
      // mintedAmt = 10000;
      // mintedAmt = 6;

      // These need to be batched eventually
      setWallet(address);
      setStatus(status);
      setNetwork(network);
      setConnected(connected);

      setMinted(mintedAmt);
      setTotal(totalSupply);
      MAX_SUPPLY = totalSupply;

      getPrice(mintedAmt, 1);

      const shortContractStr = await makeShortContract();
      setShortContract(shortContractStr);

      //GET FROM CONTRACT
      // Price per mint
      // Max per Tx
      // Max Free
      //GET FROM CONTRACT

      // setSoldOut(true);

      // console.log({network});
      // console.log({address});

      //const {testStatus} = await testStatus1();
      //console.log(testStatus);
      //setStatus(testStatus);

      addWalletListener();
      //console.log("MAX_SUPPLY -> " + MAX_SUPPLY);
      mintUpdates(MAX_SUPPLY);
      setqtyButtonDisabled(false);
    };

    fetchData();
  }, []);


  const testStatus1 = async () => {
    let txURL = "https://rinkeby.etherscan.io/tx/";
    let txID = "0x60f63faa3b5770130ee1e19ca65846c02439a65c2c475aa88a6c6f45cf6d5ba6";
    return {
      testStatus: (<span>✅ &nbsp; Success! <a target='_blank' href={txURL + txID}>Heres the Tx</a></span> )
    }
  };

  const makeShortContract = async () => {
    let shortContract = "";
    if (process.env.REACT_APP_CHAIN == 4) {
      shortContract = String(process.env.REACT_APP_CONTRACT_RINK).substring(0, 6) +
      "..." +
      String(process.env.REACT_APP_CONTRACT_RINK).substring(38);
    }
    else {
      shortContract = String(process.env.REACT_APP_CONTRACT_MAIN).substring(0, 6) +
      "..." +
      String(process.env.REACT_APP_CONTRACT_MAIN).substring(38);
    }
    // console.log("SHORT CTRCT -> " + shortContract);
    return shortContract;
  }


  const mintUpdates = (maxSupply) => {
    setInterval(async () => {
      //console.log('Logs every 20 secs');
      var {mintedUpdate, saleEnd} = await getMints(maxSupply);
      //console.log("mintUpdtes -> " + mintedUpdate);
      setMinted(mintedUpdate);
      setSoldOut(saleEnd);

      // console.log("mintUpdtes counter ref -> " + counterRef.current);
      getPrice(mintedUpdate, counterRef.current);
    }, INTEVERAL_MS);
  };


  const getPrice = async (minted, amt) => {
    let tot = parseInt(minted) + parseInt(amt);

    // if not allowing any free per wallet, but overall amount free
    // like first 1000 free
    if (process.env.REACT_APP_FREE_PER_WALLET < 1) {
      // console.log("X OVERALL FREE");
      if (tot <= process.env.REACT_APP_FREE_AMT)
      {
        //console.log("FREEEE YOU BETTA HURRY BEACHES");
        
        //console.log("MINTED + AMT ->" + tot);
        setPrice("FREE");
        setPriceNum("0");
        // return 0;
      }
      else {
        let price = process.env.REACT_APP_PRICE;
        let newPrice = amt * price;
        setPrice(newPrice + " eth");
        setPriceNum(price);
        // return process.env.REACT_APP_PRICE;
        // console.log("GET PRICE COUNT ->" + amt);
      }
    }
    // Else we are allowing x free per wallet
    else {
      // console.log("X FREE PER WALLET");
      // console.log("WALLET ADDY -> " + walletAddressRef.current);
      let mintedSoFar = 0;

      if (walletAddressRef.current) {
        mintedSoFar = await getWalletMintAmount(walletAddressRef.current);
      }
      //const mintedSoFar = 4;
      // console.log("TOTAL MINTED BY WALLET -> " + mintedSoFar);
      // console.log("NEW TOTAL -> " + amt + " + " + mintedSoFar);

      if (parseInt(mintedSoFar) + parseInt(amt) <= process.env.REACT_APP_FREE_PER_WALLET)
      {
        // console.log("FREEEE YOU BETTA HURRY BEACHES");
        
        //console.log("MINTED + AMT ->" + tot);
        setPrice("FREE");
        setPriceNum("0");
        // return 0;
      }
      else {
        let price = process.env.REACT_APP_PRICE;
        let newPrice = amt * price;
        setPrice(newPrice + " eth");
        setPriceNum(price);
        // console.log("PRICE FREE PER WALLET -> " + newPrice);
        // return process.env.REACT_APP_PRICE;
        // console.log("GET PRICE COUNT ->" + amt);
      }
    }
  }  

  const connectWalletPressed = async () => { 
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    setNetwork(walletResponse.network);
    setConnected(walletResponse.connected);
  };


  const onMintPressed = async () => {
      let price = curPriceNumRef.current;
      const amt = counterRef.current;
      // console.log("AMT -> " + counter);
      console.log("**LET'S MINT " + counter + " GREMLINZzZz BEECHES**");

      //If x per wallet free, need to adjust price 
      if (process.env.REACT_APP_FREE_PER_WALLET > 0) {
        const mintedSoFar = await getWalletMintAmount(walletAddress);
        //console.log("TOTAL MINTED BY WALLET -> " + mintedSoFar);
        if (parseInt(mintedSoFar) + parseInt(amt) <= process.env.REACT_APP_FREE_PER_WALLET) {
          // console.log ("STILL FREE: " + price);
          price = 0;
        }
      }

      const { status } = await mintNFT(amt, price);
      setStatus(status);
  };    


  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          let networkName = "";
          let chainID = "";

          let networkListener = window.ethereum.networkVersion;
          setNetwork(networkListener);

          if (process.env.REACT_APP_CHAIN === "4") {
              //console.log('RINKEBY');
              //console.log(process.env.REACT_APP_CHAIN);
              networkName = "Rinkeby";
              chainID = "0x4";
          }
          else if (process.env.REACT_APP_CHAIN === "1") {
              //console.log('MAINNET');
              //console.log(process.env.REACT_APP_CHAIN);
              networkName = "Mainnet";
              chainID = "0x1";
          }

          setWallet(accounts[0]);
          if (networkListener == process.env.REACT_APP_CHAIN) {
            setStatus("👆🏽 Wallet Connected.  Time to Mint!");
          }
          else {
            setStatus("Please switch to the " + networkName + " network");
            // console.log("Cur Network " + networkListener);
            // console.log("Network Name " + networkName);
          }

        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask if u wanna dig Gremlinzz");
        }
      });
      window.ethereum.on("networkChanged", (networkId) => {
        let networkName = "";
        let chainID = "";
        if (process.env.REACT_APP_CHAIN === "4") {
            //console.log('RINKEBY');
            //console.log(process.env.REACT_APP_CHAIN);
            networkName = "Rinkeby";
            chainID = "0x4";
        }
        else if (process.env.REACT_APP_CHAIN === "1") {
            //console.log('MAINNET');
            //console.log(process.env.REACT_APP_CHAIN);
            networkName = "Mainnet";
            chainID = "0x1";
        }

        if (networkId == process.env.REACT_APP_CHAIN) {
          setStatus("Wallet Connected!");
        }  
        else {
          setStatus("Please Connect to " + networkName + " network");         
        }  
        setNetwork(networkId);
      });
    } else {
      setStatus(
        <span>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            Metamask not found dummy
          </a>
        </span>
      );
    }

  }

  const incCounter = async () => {
    // Counter state is incremented
    if (counter < process.env.REACT_APP_MAX_PER_TX) {
      var localCount = counter + 1;
      setCounter(counter + 1)
      

      const success = await getPrice(mintedAmt, localCount);
      // console.log("POST INC COUNT ->" + counterRef.current);
      // console.log("INC MINTED ->" + mintedAmt);
      // console.log("--------");

    }
    else {
      // setCounter(1);
    }
  }

  const decCounter = async () => {
    // Counter state is deccremented
    if (counter > 1) {
      var localCount = counter - 1;
      setCounter(counter - 1);

      const success = await getPrice(mintedAmt, localCount);
      // console.log("POST DEC COUNT ->" + counterRef.current);
      // console.log("DEC MINTED ->" + mintedAmt);
      // console.log("--------");
    }
  }
  

  function WalletMintButton(props) {
    if (props.saleDone) {
      return (
        <div id='noGremlinz'>
          <h1 class='gremlinzzAllDug'>SOLD OUT!</h1>
          <a target='blank' href='https://opensea.io/collection/gremlin-underground'>
              Buy on OpenSea
          </a>
        </div>
      )
    }

    if (props.walletConnected && props.network === process.env.REACT_APP_CHAIN) {
      return (
        <button 
          id='digzDaGremlinz'
          onClick={onMintPressed} 
        >
          Digz Up Gremlin <br/> <span>(Dat menz MINT stoopid)</span>
        </button>       
      );
    }

    return (
        <button 
          id='connectDaGremlinz'
          // id="walletButton" 
          onClick={connectWalletPressed}>
            {walletAddress.length > 0 ? (
              "Connected: " +
              String(walletAddress).substring(0, 6) +
              "..." +
              String(walletAddress).substring(38)
            ) : (
              <span>Connect Wallet</span>
            )}
        </button>
    );
  }

  function TotalCost(props) {
    if (props.saleDone === true) return null;

    return (
      <div id='daCost'>
        <span>Total</span>
        <span>{curPrice} + gas</span>
      </div>
    )
  }

  function MintAmountCount(props) {
    if (props.saleDone === true) return null;


    return (
      <div id="CountButtonz">
          <button
          className='countz'
          onClick={decCounter}
          disabled={qtyButtonDisabled}
          >
            -
          </button>
          <span>{counter}</span>
          <button 
          className='countz' 
          onClick={incCounter}
          disabled={qtyButtonDisabled}
          >
            +
          </button>
      </div>
    )    
  }

  function Status(props) {
    if (props.saleDone === true) return null;


    return (
      <div id='status'>{status}</div>
    )    
  }

function delay(n) {
    return new Promise(function(resolve) {
        setTimeout(resolve,n*500)
        }
    )
}

const boner = async () => {
  await delay(6);
  setBonerActive(!isBonerActive);
  setEnterButtonDisabled(false);
  setEnterButtonClass('goUnderz');
};

function allowEntry() {
    // boner()
    // setEnterButtonDisabled(false);
}


const downUnderThunder = async () => {
  // world.classList.add("loaded")
  console.log("LETS FUCKKKK")  
  setEnterButtonClass('getFaded drunk');
  setTimeout(function(){
    setGoUnderShow(false)
    console.log("LETS FUCKKKK")
    scrollUnderground();
  },1500);

}

function scrollUnderground () {
  /* window.scrollTo({ behavior: 'smooth', top: fukinGremliz.current.offsetTop + (fukinGremliz.current.offsetHeight / 1.25 ) }) */
  window.scrollTo({ behavior: 'smooth', top: fukinGremliz.current.offsetTop  + fuckinGremlinFlex.current.offsetTop + fuckinGremlinFlex.current.offsetHeight + 50 })
  /* window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" }); */
}

function goUnderButton (hideUnderButton) {
  if (!hideUnderButton) return null;
  else return true;
}

function GoFuckingUnderground(props) {
  if (isBonerActive === false) return (<div>Go <span>Fucking</span> Underground</div>);

  return (
    <div>Hold your titties</div>
  )    
}

  
  return (
    
    <div id="WorldWrapper" className={isBonerActive ? "HoldYourTits" : "Boner"}>
      <div id="AboveGround">
      {goUnderShow && <button id="goUnder" className={enterButtonClass} onClick={downUnderThunder} disabled={enterButtonDisabled}><GoFuckingUnderground /></button>}
        <div id='BigHardTing'>
          <div id="greenShits">
            <img src='/images/goblin_toast.png' />
            <img src='/images/goblin_decapitated.png' />
          </div>
          <img id='BigHardTing_Base' src='/images/BASE-BigHardTing.png' />
          <img id='BigHardTing_Animate' src='/images/BigHardTing-Animate.gif' />
          <img id='burner' src='/images/wood_on_fire.png' />
          <img id='scaffy_fire' src='/images/scaff_fire.png' />
          <img id='gobbie_toast' src='/images/gobbie_burn.png' />
          <img id='mount_burn' src='/images/bigHardTing_burn.png' />
        </div>
        <div id="gobbieCitee">
          <img src='/images/FT-0-smok-flappy.png' />
          <img src='/images/FT-1-burnie.png' />
          <img src='/images/FT-2-ash-citee.png' />
          <img src='/images/FT-3-ash-highlandz.png' />
          <img src='/images/FT-4-hippee-citee-east.png' />
          <img id='FT_midz_burnz' src='/images/FT-5-midz-burnz.png' />
          <img src='/images/FT-6-hippee-citee-west.png' />
          <img id='FT_hippee_citee_w_toast' src='/images/FT-7-hippee-citee-west-toast.png' />
          <img src='/images/FT-8-poopie-citee.png' />
          <img src='/images/FT-9-erect-citee.png' />
          <img src='/images/FT-10-mudz-butt-citee.png' />
          <img id='FT_frontal_burnz' src='/images/FT-11-frontal-burnz.png' />
        </div>
      </div>
      <div id="GremlinUnderground">
        <div id='gobbie_toastin'>
          <img src='/images/gobbie-toastin.png' />
        </div>
        <img id="rockz" src="/images/GremlinRockz.png" />
        <img id="dirtz" src="/images/GremlinDirtz.png" />
        <img id="lavaz" src="/images/GremlinLavaz.png" />
        <div id="darknezz" ref={fukinGremliz}>
          <div id="DaLairHodler">
            <div id="holdGremlinz">
              <div id="printGremlinz" ref={fuckinGremlinFlex}>
                <div id="gremlinFlex">
                    <div id="wekm">WEKM TA UNDERGRND</div>
                    <div id="readDis">
                      First 1969 Free. We can't afford anymore...
                      <br />
                      After that... cost is 0.0069 ETH + gas each 
                    </div>
                    <div id="GremlinzStuff">
                      <div id="peepGremlinz">
                        <div id="countzGremlinz">
                          {mintedAmt} / {totalSupply}
                        </div>
                        <img id="seeez" src='/images/gremmies-teaz.gif' alt='rekt' />
                      </div>
                      <div id="digzGremlin">
                        <MintAmountCount saleDone={soldOut} />
                        <TotalCost saleDone={soldOut} />
                        <WalletMintButton saleDone={soldOut} walletConnected={walletAddress} network={network} />
                        <Status saleDone={soldOut} />
                      </div>
                    </div>
                    <div id="gremlinLinkz">
                      <a target='blank' href='https://twitter.com/gremlinzbelow'>
                        <img src='/images/Twatter.png' />
                        </a>
                      <a target='blank' href='https://opensea.io/collection/gremlin-underground'>
                        <img src='/images/ClosedSea.png' />
                      </a>
                      {process.env.REACT_APP_CHAIN == 4 && <a style={{color: 'red', textDecoration: 'underline'}} target='_blank' href={'https://rinkeby.etherscan.io/address/' + process.env.REACT_APP_CONTRACT_RINK}><img src='/images/GremlinScan.png' /></a>}
                      {process.env.REACT_APP_CHAIN == 1 && <a style={{color: 'red'}} target='_blank' href={'https://etherscan.io/address/' + process.env.REACT_APP_CONTRACT_MAIN}><img src='/images/GremlinScan.png' /></a>}
                    </div>    
                    <div id='noPlan'>
                      CC0. No Discord. No Roadmap. NO Promises.  We Reserve 569.
                    </div>          
                </div> 
              </div>
              <div id="TwatterHash">
                      #GOINGUNDERGROUND
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MinterGremlinz;
